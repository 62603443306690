import {createRouter, createWebHistory} from 'vue-router'
import Login from "@/views/Login";
import ForgetPassword from "@/components/ForgetPassword";
import Dashboard from "@/views/Dashboard";
import SectionContainer from "@/components/SectionContainer";
import Appendix from "@/components/Appendix";
import NextStep from "@/components/NextStep";
import Reports from "@/components/Reports";
import ManageUsers from "@/components/ManageUsers";
import ManageClient from "@/components/ManageClient";
import ManageTenant from "@/components/ManageTenant";
import ManageFeatures from "@/components/ManageFeatures";
import ManageCampaignTemplates from "@/components/ManageCampaignTemplates";
import PublicSurvey from "@/views/PublicSurvey.vue";
import IPReport from "@/views/IPReport.vue";
import store from '@/store';
import SurveyCreator from "@/views/SurveyCreator.vue";
import PrivateSurvey from "@/views/PrivateSurvey.vue";

const public_pages = ['login', 'home', 'publicSurvey'];

const routes = [
    {path: '/', name: 'home', component: Login},
    {path: '/login', name: 'login', component: Login},
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        children: [
            {
                path: 'explore/participants-breakdown',
                name: 'breakdown',
                component: SectionContainer,
                props: {type: 'participants-break-down'},
            },
            {
                path: 'explore/nps-breakdown',
                name: 'nps',
                component: SectionContainer,
                props: {type: 'nps'},
            },
            {
                path: 'explore/staff-retention-factors',
                name: 'srf',
                component: SectionContainer,
                props: {type: 'srf'},
            },
            {
                path: 'explore/staff-attrition-factors',
                name: 'saf',
                component: SectionContainer,
                props: {type: 'saf'},
            },
            {
                path: 'explore/total-value-proposition',
                name: 'tvp',
                component: SectionContainer,
                props: {type: 'tvp'},
            },
            {
                path: 'explore/tvp-lowlights',
                name: 'tvp-lowlights',
                component: SectionContainer,
                props: {type: 'lowlights'},

            },
            {
                path: 'explore/tvp-highlights',
                name: 'tvp-highlights',
                component: SectionContainer,
                props: {type: 'highlights'},

            },
            {
                path: 'explore/tvp-low-variance',
                name: 'tvp-low-variance',
                component: SectionContainer,
                props: {type: 'low-variance'},

            },
            {
                path: 'explore/tvp-high-variance',
                name: 'tvp-high-variance',
                component: SectionContainer,
                props: {type: 'high-variance'},

            },
            {
                path: 'explore/tvp-disparities',
                name: 'tvp-disparities',
                component: SectionContainer,
                props: {type: 'tvp-disparities'},

            },
            {
                path: 'explore/edgility-pillars-of-equity',
                name: 'pillars-equity',
                component: SectionContainer,
                props: {type: 'pillars-of-equity'},

            },
            {
                path: 'explore/equity-index',
                name: 'equity-index',
                component: SectionContainer,
                props: {type: 'equity-index'},

            },
            {
                path: 'explore/appendix',
                name: 'appendix',
                component: Appendix,

            },
            {
                path: 'explore/next-steps',
                name: 'next-steps',
                component: NextStep,

            },
            {
                path: 'explore/sort-sections',
                name: 'sort-sections',
                component: NextStep,

            },
            {
                path: 'manage-campaign-templates',
                name: 'manage-campaign-templates',
                component: ManageCampaignTemplates,
            },
            {
                path: 'manage-user',
                name: 'manage-users',
                component: ManageUsers,
            },
            {
                path: 'manage-client',
                name: 'manage-client',
                component: ManageClient,
            },
            {
                path: 'manage-tenant',
                name: 'manage-tenant',
                component: ManageTenant,
            },
            {
                path: 'manage-features',
                name: 'manage-features',
                component: ManageFeatures,
            },
            {
                path: 'explore/reports',
                name: 'reports',
                component: Reports,
            },
        ]
    },
    {path: '/survey/:survey_id', name: 'survey', component: PrivateSurvey},
    {path: '/survey-creator/:survey_template_id', name: 'surveyCreator', component: SurveyCreator},
    {path: '/forget-password', name: 'forgetPassword', component: ForgetPassword},
    {path: '/public/survey/:surveyUniqueID', name: 'publicSurvey', component: PublicSurvey},
    {path: '/report/:uniqueId/', name: 'survey-report', component: IPReport},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes
})

router.afterEach(async(to, from, failure) => {
    if (to.name === 'dashboard'){
        store.state.active = 'campaign'
    }
    else{
        store.state.active = to.name
    }
})

router.beforeEach( async (to, from, next) => {
    let token = localStorage.getItem('token')

    try {
        if (public_pages.includes(to.name)){
            next()
        }

        else if (to.name === 'forgetPassword'){
            if (to.query.token === undefined || to.query.token === null || to.query.token.replace(' ', '') === ''){
                if (from.name === 'login' || from.name === 'home'){
                    next()
                }
                else {
                    next('/login')
                }
            }
            else {
                next()
            }
        }

        else if (!Boolean(token)){
            next('/login')
        }
        else if (to.name === 'dashboard'){
            store.commit('setActivepage', 'campaign')
            next()
        }
        else if (to.fullPath.includes('explore')){
            if (store.state.hasReportData) {
                // store.commit('setActivepage', to.name)
                next()
            }
            else {
                // store.commit('setActivepage', 'campaign')
                next('/dashboard')
            }
        }
        else {
            next()
        }
    }

    catch {
        this.$toast.error("There is an Error loading the Page...")
    }

})
export default router
