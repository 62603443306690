<template>
        <hr />
        <p class="fs-5 fw-bolder">Compare the current survey with other survey:</p>
        <div class="d-flex justify-content-between m-3 align-items-center">
            <div class="w-75">
                <div class="d-flex flex-row align-items-center justify-content-around w-75">
                    <div class="fw-bolder">Select Survey to compare:</div>
                    <multiselect style="width: 300px;" @select="this.compareChart()" v-model="selected_survey" :options="surveys.results" track-by="id" label="title" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Survey"></Multiselect>
                </div>
            </div>

            <div>
                <input class="form-check-input me-3" type="checkbox" v-model="compare_surveys" @change="compareChart" :disabled="selected_survey === null">
                <label class="form-check-label fw-bolder" for="defaultCheck10">
                    Compare Surveys
                </label>
            </div>
        </div>
        <!-- <p>Hello</p> -->

</template>


<script>
import axios from 'axios'

    export default {
        name: "ComparativeChart",
        props: ['surveys'],
        data(){
            return {
                selected_survey: null,
                compare_surveys: false
            }
        },
        mounted(){
            console.log(this.surveys)
            console.log(this.$store.state.selectedSurveyID)
        },
        methods: {
            async compareChart(){
                await axios
                    .get(`api/render-comparative-report-data/?id1=${this.$store.state.selectedSurveyID}&id2=${this.selected_survey.id}`)
                    .then(resp => {
                        this.$store.commit('setComparativeCharts', {data1: resp.data.data1, data2: resp.data.data2})
                    })
                    .catch(err => console.log(err))

                console.log(this.$store.state.comparativeChart1)
            }
        }

    }

</script>