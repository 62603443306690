import router from '@/router'
import { computed } from 'vue'
import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
    state: {
        cart: {
            items: [],
        },
        isAuthenticated: false,
        userPermissions: {},
        token: '',
        username: '',
        isSwitched: false,
        isLoading: false,
        active: 'campaign',
        hasReportData: false,
        reportData: {},
        selectedSurveyID: '',
        compareSurveys: false,
        tenantId: 2,

        displayFiltersDemographicTypeOptions: [],

        global_filters: {},
        appliedFilters: {},
        sections: [],
        
        comparativeChart1: [],
        comparativeChart2: [],


        tenantFeatures: {
            comparativeCharts: false,
            tvpDisparities: false
        },

        featuresEnum: {
            comparativeCharts: 'comparative_charts',
            tvpDisparities: 'tvp_disparities'
        }

    },
    mutations: {
        initializeStore(state) {
            // if (localStorage.getItem('cart')) {
            //     state.cart = JSON.parse(localStorage.getItem('cart'))
            // } else {
            //     localStorage.setItem('cart', JSON.stringify(state.cart))
            // }
            console.info("initializing store")

            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token')
                state.isAuthenticated = true
            } else {
                state.token = ''
                state.isAuthenticated = false
            }
        },
        addToCart(state, item) {
            // const exists = state.cart.items.filter(i => i.product.id === item.product.id)
            // if (exists.length) {
            //     exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
            // } else {
            //     state.cart.items.push(item)
            // }
            //
            // localStorage.setItem('cart', JSON.stringify(state.cart))
        },
        setIsLoading(state, status) {
            state.isLoading = status
        },
        setToken(state, token) {
            state.token = token
            state.isAuthenticated = true
        },
        removeToken(state) {
            state.token = ''
            state.isAuthenticated = false
        },
        clearCart(state) {
            // state.cart = { items: [] }
            //
            // localStorage.setItem('cart', JSON.stringify(state.cart))
        },
        setActivepage(state, page){
            state.active = page
        },
        setHasReportData(state, status){
            state.hasReportData = status
        },
        setReportData(state, data){
            state.reportData = data
            console.log(state.reportData)
            state.sections = state.reportData.sections
            console.log(state.sections)
        },
        setComparativeCharts(state, data){
            state.comparativeChart1 = data.data1.sections
            console.log("Commiting")
            state.comparativeChart2 = data.data2.sections
        },
        resetComparativeSurveyData(state) {
            state.sections1 = []
            state.compareSurveys = false
        },
        addFilter(state, key, value){
            state.demographicFilters[key] = value
        },
        resetParams(state){
            state.custom_filters = {
                global_filters: {},
                section_filters_demographics: {},
                section_filters_nps: {},
                section_filters_srf: {},
                section_filters_tvp: {},

                display_filters_demographics: {
                    display_chartss: []
                },
                display_filters_nps: {
                    display_charts: []
                },

                display_filters_tvp: {},

                display_filters_srf: {
                    display_count: 17
                },
                display_filters_saf: {
                    display_count: 17
                },
                display_filters_tvp_highlights: {
                    display_count: 15
                },
                display_filters_tvp_lowlights: {
                    display_count: 15
                },
            }
        },

        async setTenantFeatures(state){
            await axios
                .get(`api/features-list/${state.tenantId}/`)
                .then(resp => {
                    console.log("Successfully setTenantFeatures")
                    console.log(resp.data)
                    for (let feature of resp.data.features){
                        if (feature.feature === state.featuresEnum.comparativeCharts)
                            state.tenantFeatures.comparativeCharts = feature.enabled
                        else if (feature.feature === state.featuresEnum.tvpDisparities)
                            state.tenantFeatures.tvpDisparities = feature.enabled
                    }
                    console.log(state.tenantFeatures)
                })
                .catch(err => console.log(err))
        },

        applyFilters(state){
            state.appliedFilters = {...state.global_filters}
        },

        resetAppliedFilters(state){
            state.appliedFilters = {}
        },

        switchBack(state){
            const adminToken = localStorage.getItem("adminToken")
            localStorage.setItem("token", adminToken)
            state.username = JSON.parse(adminToken).username
            localStorage.removeItem('adminToken')
            state.userPermissions = JSON.parse(adminToken).userPermissions
            state.isSwitched = false
            state.reportData = {}
            this.commit('resetParams')
            this.commit('resetAppliedFilters')
            this.commit('setHasReportData', false)
            this.commit('setActivepage', 'breakdown')
            router.push('/dashboard')
        }

    },
    actions: {
    },
    modules: {
    },
    getters: {
        userPermissions(state){
            let perm = JSON.parse(localStorage.getItem("token")).userPermissions
            return perm
        },

        params(state){
            let globalFilters = JSON.stringify(state.global_filters)
            var sections = []
            state.sections.map((section) => {
                sections.push({
                    title: section.title,
                    type: section.type,
                    filters: section.filters,
                    duplicate: section.duplicate
                })
            })

            sections = JSON.stringify(sections)

            return {
                global_filters: globalFilters,
                sections: sections
            }
        },

        paramsForDuplicateSection: (state) => (section, index) => {
            let globalFilters = JSON.stringify(state.custom_filters.global_filters)
            var sections = []
            state.sections.map((section) => {
                sections.push({
                    title: section.title,
                    type: section.type,
                    filters: section.filters,
                    duplicate: section.duplicate
                })
            })

            sections.splice(index, 0, {
                title: section.title,
                type: section.type,
                filters: section.filters,
                duplicate: section.duplicate
            });

            sections = JSON.stringify(sections)

            return {
                global_filters: globalFilters,
                sections: sections
            }
        },

        paramsForCustomReport: (state) => (sections) => {
            let globalFilters = JSON.stringify(state.custom_filters.global_filters)
            var sections = []
            sections.map((section) => {
                sections.push({
                    title: section.title,
                    type: section.type,
                    filters: section.filters,
                    duplicate: section.duplicate
                })
            })

            sections = JSON.stringify(sections)
            return {
                global_filters: globalFilters,
                sections: sections
            }
        },
    }
})
